import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: true,
    hasScrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    const currentScroll = window.pageYOffset
    let scrollThreshold = 149
    let windowHeight = window.innerHeight
    let headerHeight = 80
    if (window.innerWidth <= 900) {
      headerHeight = 69
      windowHeight = 404
    }
    let bodyClass = document.querySelector('body').classList
    if ( bodyClass && bodyClass.length && bodyClass[0] === 'single-place__body' ) scrollThreshold = windowHeight - headerHeight
    if (currentScroll > scrollThreshold) {
      this.setState({ 
        hasScrolled: true,
        scrolled: true,
      })
    } else {
      this.setState({ scrolled: false })
    }
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled, hasScrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (hasScrolled) headerClass += ' show-burger'
    if (scrolled) headerClass += ' header--scrolled'

    return (
      <div className='header__wrapper'>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='SRL' className='header__logo' {...props}>
              <Logo color={`#576946`} />
            </Link>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'active' }`}>
          <button className='off-canvas__close' onClick={this._toggleOffCanvas} />
          <div className='off-canvas__inner'>
            <Link to='/' title='SRL' className='off-canvas__logo' {...props}>
              <Logo color={`#FFFFFF`} />
            </Link>
            <button onClick={this._toggleOffCanvas} className='off-canvas__hamburger active'>
              <span className='lines'></span>
            </button>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/our-places/' {...props}>Our Places</Link></li>
                <li><Link to='/our-approach/' {...props}>Our Approach</Link></li>
                <li><Link to='/about/' {...props}>About</Link></li>
                <li><Link to='/journal/' {...props}>Journal</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
