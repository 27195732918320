import React, { Component } from 'react'

/*
class Logo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="114" height="47" viewBox="0 0 114 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M21.6533 19.628L16.1684 18.6126C11.9127 17.8205 9.759 15.9622 9.759 13.0885C9.759 9.02675 14.7016 7.57466 18.0225 7.57466C21.8753 7.57466 26.7508 8.71196 28.0833 14.1294L28.1349 14.3578H36.3261L36.2538 14.0024C34.5753 5.39145 28.2537 0.664551 18.4511 0.664551C14.1335 0.664551 9.87778 1.81708 6.76864 3.82766C3.15336 6.15811 1.26309 9.41262 1.26309 13.3221C1.26309 19.9733 5.67889 24.2483 14.3865 26.0152L19.8404 27.0662C24.2614 27.8836 28.7753 29.2951 28.7753 33.5599C28.7753 37.9162 24.1271 39.8659 19.5254 39.8659C13.4827 39.8659 9.7125 36.9058 8.35935 31.067L8.30255 30.8385H0.0390625L0.075219 31.1635C1.06684 40.9321 8.20443 46.7607 19.1845 46.7607C28.1556 46.7607 37.2351 42.0592 37.2351 33.0522C37.2351 23.7 28.9354 20.9582 21.7049 19.6128" fill={color}/>
          <path d="M67.7961 14.4709C67.7961 19.7207 63.8813 20.7768 57.5649 20.7768H50.1019V8.22587H57.3324C63.9691 8.22587 67.8116 9.24131 67.8116 14.4709H67.7961ZM76.4314 14.4099C76.4314 5.20491 70.5747 1.44775 56.222 1.44775H41.668V45.97H50.1019V27.494H57.3789C58.102 27.494 58.8457 27.494 59.5378 27.4432L68.9633 45.97H78.456L68.1576 25.9099C73.6373 23.9602 76.4211 20.0964 76.4211 14.4099" fill={color}/>
          <path d="M83.0684 1.44775V45.97H114V38.8923H91.5023V1.44775H83.0684Z" fill={color}/>
        </g>
      </svg>
    )
  }
}
*/

const Logo = ({ color = '#576946' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 226.8 113.4">
    <title>Sandhurst Retail & Logistics</title>
    <path fill={color} d="M19.5,26.4c-3.9-.8-7.1-2.8-7.1-7s4.8-7,9.4-7,8.7,2.2,9.7,7.4h-4.2c-.7-3-3.3-3.8-5.7-3.8s-4.7.9-4.7,3.3,1.5,2.9,3.7,3.3l3,.6c4.3.8,8.5,2.5,8.5,7.4s-5,7.6-9.8,7.6-9.9-3.2-10.4-8.6h4.3c.8,3.4,2.9,5.1,6.4,5.1s5.3-1.3,5.3-3.7-2.7-3.4-5.1-3.8l-3-.6Z"/>
    <path fill={color} d="M41.7,12.9h4.6l9.3,24.8h-4.7l-1.9-5.5h-10l-1.9,5.5h-4.4l9.2-24.8ZM40.1,28.5h7.5l-3.8-10.6-3.7,10.6Z"/>
    <path fill={color} d="M72.6,37.6l-10.5-19.8v19.8h-4.2V12.9h5.8l10.5,19.8V12.9h4.2v24.8h-5.8Z"/>
    <path fill={color} d="M88.6,12.9c7.8,0,13.8,2.7,13.8,12.4s-6,12.4-13.8,12.4h-6.8V12.9h6.8ZM88.4,16.4h-2.2v17.8h2.2c4.7,0,9.5-1.1,9.5-8.9s-4.8-8.9-9.5-8.9Z"/>
    <path fill={color} d="M125.9,12.9v24.8h-4.3v-10.9h-11.8v10.9h-4.3V12.9h4.3v10.2h11.8v-10.2h4.3Z"/>
    <path fill={color} d="M133.5,12.9v14.6c0,4.5,1.2,7,5.8,7s5.8-2.6,5.8-7.2v-14.5h4.3v14.3c0,7.8-2.9,10.9-10.1,10.9s-10.1-2.8-10.1-10.8v-14.5h4.3Z"/>
    <path fill={color} d="M162.8,27.2c-.4,0-.9,0-1.3,0h-4.2v10.4h-4.3V12.9h7.9c7.3,0,11,1.6,11,7.1s-1.5,5.3-4.6,6.4l5.7,11.2h-4.9l-5.2-10.5ZM157.3,16.4v7.4h4.3c3.7,0,5.8-.8,5.8-3.7s-2.6-3.7-6-3.7h-4.2Z"/>
    <path fill={color} d="M181.9,26.4c-3.9-.8-7.1-2.8-7.1-7s4.8-7,9.4-7,8.7,2.2,9.7,7.4h-4.2c-.7-3-3.3-3.8-5.7-3.8s-4.7.9-4.7,3.3,1.5,2.9,3.7,3.3l3,.6c4.3.8,8.5,2.5,8.5,7.4s-5,7.6-9.8,7.6-9.9-3.2-10.4-8.6h4.3c.8,3.4,2.9,5.1,6.4,5.1s5.3-1.3,5.3-3.7-2.7-3.4-5.1-3.8l-3-.6Z"/>
    <path fill={color} d="M203,16.5h-7.8v-3.6h19.9v3.6h-7.8v21.1h-4.3v-21.1Z"/>
    <path fill={color} d="M23.1,58.6c-.4,0-.9,0-1.3,0h-4.2v10.4h-4.3v-24.8h7.9c7.3,0,11,1.6,11,7.1s-1.5,5.3-4.6,6.4l5.7,11.2h-4.9l-5.2-10.5ZM17.6,47.8v7.4h4.3c3.7,0,5.8-.8,5.8-3.7s-2.6-3.7-6-3.7h-4.2Z"/>
    <path fill={color} d="M36.1,44.3h17.5v3.6h-13.2v6.6h12.5v3.6h-12.5v7.3h13.2v3.7h-17.5v-24.8Z"/>
    <path fill={color} d="M63.1,47.9h-7.8v-3.6h19.9v3.6h-7.8v21.1h-4.3v-21.1Z"/>
    <path fill={color} d="M81.6,44.3h4.6l9.3,24.8h-4.7l-1.9-5.5h-10l-1.9,5.5h-4.4l9.2-24.8ZM80,60h7.5l-3.8-10.6-3.7,10.6Z"/>
    <path fill={color} d="M102.1,69.1h-4.3v-24.8h4.3v24.8Z"/>
    <path fill={color} d="M110.3,65.4h12.5v3.7h-16.8v-24.8h4.3v21.1Z"/>
    <path fill={color} d="M129.5,62.2c0-3.8,2.7-6.1,6-7.3-1.6-1.9-2.6-3.7-2.6-5.6,0-3.3,2.9-5.4,6.9-5.4s6.6,1.4,6.6,5.5-2,5.1-4.8,6.4l4,4.3c.5-1.5.9-3,1-4.6h4.1c-.3,2.6-1.1,5.2-2.4,7.5l5.7,6.2h-5.6l-2.6-2.9c-2,2.1-4.7,3.3-8,3.3-5,0-8.3-3.2-8.3-7.3ZM143.4,63.7l-5.8-6.4c-2.6,1-3.9,2.5-3.9,4.7s1.7,4.2,4.7,4.2,3.7-1,5-2.5ZM139.5,53.4h0c2.1-.9,3-2.4,3-3.9s-1.2-2.5-2.7-2.5-2.7.8-2.7,2.2,1.2,2.9,2.4,4.2Z"/>
    <path fill={color} d="M17.6,96.9h12.5v3.7H13.2v-24.8h4.3v21.1Z"/>
    <path fill={color} d="M42.5,101c-7.3,0-11.8-5.2-11.8-12.8s4.5-12.8,11.8-12.8,11.8,5.2,11.8,12.8-4.5,12.8-11.8,12.8ZM42.5,97.4c4.1,0,7.3-3.2,7.3-9.3s-3.2-9.3-7.3-9.3-7.3,3.2-7.3,9.3,3.2,9.3,7.3,9.3Z"/>
    <path fill={color} d="M79.2,83.6h-4.1c-.8-3-3.1-4.7-6.4-4.7s-7.3,3.1-7.3,9.2,2.9,9.4,7.3,9.4,6.6-1.3,6.6-5.7v-.5h-6.3v-3.7h10.4v12.9h-3.4v-3c-1.6,2.2-4.3,3.4-7.7,3.4-7,0-11.3-5.1-11.3-12.9s4.5-12.7,11.9-12.7,9.4,3,10.5,8.3Z"/>
    <path fill={color} d="M87.7,100.5h-4.3v-24.8h4.3v24.8Z"/>
    <path fill={color} d="M98.7,89.3c-3.9-.8-7.1-2.8-7.1-7s4.8-7,9.4-7,8.7,2.2,9.7,7.4h-4.2c-.7-3-3.3-3.8-5.7-3.8s-4.7.9-4.7,3.3,1.5,2.9,3.7,3.3l3,.6c4.3.8,8.5,2.5,8.5,7.4s-5,7.6-9.8,7.6-9.9-3.2-10.4-8.6h4.3c.8,3.4,2.9,5.1,6.4,5.1s5.3-1.3,5.3-3.7-2.7-3.4-5.1-3.8l-3-.6Z"/>
    <path fill={color} d="M119.9,79.4h-7.8v-3.6h19.9v3.6h-7.8v21.1h-4.3v-21.1Z"/>
    <path fill={color} d="M138.9,100.5h-4.3v-24.8h4.3v24.8Z"/>
    <path fill={color} d="M163.8,92.3c-1.1,5.6-5,8.7-10.7,8.7s-11.4-5.1-11.4-12.9,4.4-12.7,11.7-12.7,9.4,3.1,10.4,8.7h-4.2c-.8-3.4-3.1-5.1-6.4-5.1s-7,3.2-7,9.2,3,9.4,7,9.4,5.7-1.7,6.5-5.1h4.2Z"/>
    <path fill={color} d="M173.7,89.3c-3.9-.8-7.1-2.8-7.1-7s4.8-7,9.4-7,8.7,2.2,9.7,7.4h-4.2c-.7-3-3.3-3.8-5.7-3.8s-4.7.9-4.7,3.3,1.5,2.9,3.7,3.3l3,.6c4.3.8,8.5,2.5,8.5,7.4s-5,7.6-9.8,7.6-9.9-3.2-10.4-8.6h4.3c.8,3.4,2.9,5.1,6.4,5.1s5.3-1.3,5.3-3.7-2.7-3.4-5.1-3.8l-3-.6Z"/>
  </svg>
)

class Arrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.14 10.1L1.38 11.34L9.66 3.06V9.04H11.24V0.239999H2.44L2.42 1.8H8.42L0.14 10.1Z" fill={color}/>
      </svg>
    )
  }
}

class Play extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="29" cy="29" r="28.0333" stroke={color} strokeWidth="1.93333"/>
        <path d="M41.8889 29.3221L21.1056 41.3214L21.1056 17.3228L41.8889 29.3221Z" fill={color}/>
      </svg>
    )
  }
}

class LeftArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.09177 4.04879L16 4.04879L16 5.25437L2.09177 5.25437L4.95277 8.14504L4.19703 8.91223L3.49691e-07 4.65158L4.19703 0.390928L4.95277 1.14442L2.09177 4.04879Z" fill={color}/>
      </svg>
    )
  }
}

class RightArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9082 4.04879L1.50818e-06 4.04879L1.60713e-06 5.25437L13.9082 5.25437L11.0472 8.14504L11.803 8.91223L16 4.65158L11.803 0.390928L11.0472 1.14442L13.9082 4.04879Z" fill={color}/>
      </svg>
    )
  }
}

export { Logo, Arrow, Play, LeftArrow, RightArrow }
