import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo } from './icons'

class Footer extends Component {
  render() {
    let { footer } = this.props.data.wp.siteOptions.acf
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__top'>
            <div className='footer__seo'>
              <p dangerouslySetInnerHTML={{ __html: footer.seo }} />
              <p>© Copyright Sandhurst Retail &amp; Logistics { new Date().getFullYear() }</p>
            </div>
            <div className='footer__contact'>
              <p dangerouslySetInnerHTML={{ __html: footer.address }} />
              <p><Link to={`mailto:${footer.email}`}>{footer.email}</Link></p>
              <p>
                { footer.linkedin && <Link to={footer.linkedin}>LinkedIn</Link> }
                { footer.instagram && <><br /><Link to={footer.instagram}>Instagram</Link></> }
              </p>
            </div>
          </div>
          <div className='footer__bottom'>
            <Link className='footer__logo' to='/'>
              <Logo color={`#576946`} />
            </Link>
            <div className='footer__links'>
              {footer.menu &&
                <ul>
                  {footer.menu.map((el, i) => (
                    <li key={i}><Link to={el.link}>{el.text}</Link></li>
                  ))}
                </ul>
              }
              <ul>
                <li>Site by <Link to='https://y-m-d.com/'>YMD</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
